import { useAuth } from "App";
import { UserStatus } from "constants/IUsuario";
import { useAuthUser } from "contexts/authContext";
import "./TopBar.less";
import { useDownloadFiles } from "hooks/useDownloadfile";
import { DownloadFiles } from "components/DownloadFiles";
import { useState } from "react";
import { Button, Popup } from "devextreme-react";

export const TopBar = () => {

  const {
    handlerShowMenus,
    menusState,
    clearData
  } = useAuth();
  const {
    user,
    signout,
    ready
  } = useAuthUser();

  const handlerSignOut = async (): Promise<void> => {
    clearData();
    setTimeout(() => signout(), 0);
  }
  const { DownloadZip, Getpaths, pathsFile } = useDownloadFiles()

  const OpenModalToDownload = () => {
    setOpenModal(true);
    Getpaths();
  }

  const [backUpModal, setBackUpModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Popup
        height={"auto"}
        width={'60%'}
        maxHeight={"80%"}
        maxWidth={"60%"}
        visible={openModal}
        showCloseButton={true}
        onHidden={() => setOpenModal(false)}
        title={"Download Files"}
        dragEnabled={false}

      >
        <Button
          text="Download Backup Zip"
          type='normal'
          width={'50%'}
          stylingMode="outlined"
          onClick={() => DownloadZip(pathsFile)}
        />
        <Button
          text="Download file"
          type='normal'
          width={'50%'}
          stylingMode="outlined"
          onClick={() => setBackUpModal(true)}
        />
        <DownloadFiles
          pathsFile={pathsFile}
          setBackUpModal={setBackUpModal}
          openModal={backUpModal}
        />
      </Popup>
      <div
        className="user-option"
        onClick={() => handlerShowMenus("user")}>
        <p id="user">{user?.username ?? "User"}</p>
        <i
          className="fas fa-chevron-right"
          style={{ transform: `rotate(${menusState.user ? "90deg" : "0deg"})` }}
        >
        </i>
      </div >
      <div className={`box-options ${menusState.user ? "show" : "hidden"}`}>
        <p
          onClick={() => handlerSignOut()}
        >
          <i className={ready === UserStatus.SignOut ? "fas fa-spinner rotate" : "fas fa-sign-out-alt"}></i>
          <span>Sign Out</span>
        </p>
        <p onClick={() => window.open("/policy/Privacy Policy.pdf")}>Privacy Policy</p>
        <p onClick={() => window.open("/policy/Software License.pdf")}>Software Use Licence</p>
        <p onClick={OpenModalToDownload}>Download backup</p>
      </div>
    </>
  )
};
