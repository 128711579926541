import { useAuth } from 'App';
import SubHeader from 'layout/SubHeader';
import { useNavigate } from 'react-router-dom';
import { IconBIM, IconDrawings, IconGantt, IconProductionOrder, IconReports, IconLock } from 'assets/index'
import './styles/styles.less';

const Home = () => {
  const { selectedWork, selectedClient } = useAuth();
  const navigate = useNavigate();

  return (
    <div>
      <SubHeader title={selectedWork?.work} subTitle={selectedClient?.nombre} />
      <div className='container-options-home'>
        <div
          className='item'
          onClick={() => { window.location.pathname = `/erabim/${selectedWork?.id}`; }}
        >
          <img alt='IconBim' src={IconBIM} className="icon" />
          <h3>VIEW BIM MODEL</h3>
          <p>View the registered BIM Model</p>
        </div>
        <div
          className='item'
          onClick={() => navigate("/DrawingManagement", { state: "home" })}
        >
          <img alt='IconDrawing' className='icon' src={IconDrawings} />
          <h3>PROJECT MANAGEMENT</h3>
          <p>Project documents</p>
        </div>
        <div
          className='item'
          onClick={() => navigate("/projectreports", { state: "home" })}
        >
          <img alt='IconReports' className='icon' src={IconReports} />
          <h3>REPORTS</h3>
          <p>Production statistics charts</p>
        </div>
        <div
          className={`item`}
          onClick={() => navigate("/gantt", { state: "home" })}
        >
          <img src={IconGantt} alt="IconProduction" className='icon' />
          <h3>GANTT CHART</h3>
        </div>
        <div
          className='item disabled'
        >
          <img src={IconProductionOrder} alt="IconProduction" className='icon' />
          <h3>PRODUCTION ORDERS</h3>
          <img src={IconLock} alt="IconLock" className='icon lock-icon' />
        </div>
      </div>
    </div>
  )
}

export default Home