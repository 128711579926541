import { lazy, LazyExoticComponent } from 'react';
import Works from 'pages/Home/Projects';
import Home from 'pages/Home/Home';
import Clientes from 'pages/Home/Clients';

export type JSXElement = () => JSX.Element;

export interface IRoute {
  path: string;
  to: string;
  Component: LazyExoticComponent<JSXElement> | JSXElement;
  name?: string;
  items?: IRoute[];
}
//ALL ROUTES PAGES
const StationMenu = lazy(() => import(/* webpackChunkName:"StationMenu" */'../pages/Stations/StationMenu'));
const BimModelsMenu = lazy(() => import(/* webpackChunkName:"BimModels" */'../pages/BimModels/BimModelsMenu'));
const CreateViewBim = lazy(() => import(/* webChunkName:"CreateViewBIM" */'../pages/BimModels/NewView'));
const EditViewBim = lazy(() => import(/* webChunkName:"EditViewBIM" */'../pages/BimModels/EditView'));
const ProcessEditor = lazy(() => import(/* webpackChunkName:"ProcessEditor" */'../pages/ProcessEditor/ProcessEditor'));
const PathEditor = lazy(() => import(/* webpackChunkName:"PathEditor" */'../pages/ProcessEditor/PathEditor'));
const ControlPlanosMenu = lazy(() => import(/* webpackChunkName:"MenuControlObras" */'../pages/ControlPlanos/MenuControlObras'));
const ControlPlanosIndex = lazy(() => import(/* webpackChunkName:"ControlPlanos" */'../pages/ControlPlanos/IndexControlPlanos'));
const GraficaPiezas = lazy(() => import(/* webpackChunkName:"GraficaPiezas" */'../pages/Reportes/Graficas'));
const GraficaObra = lazy(() => import(/* webpackChunkName:"GraficaPiezasObra" */'../pages/Reportes/GraficasObra'));
const GraficaHistorico = lazy(() => import(/* webpackChunkName:"GraficaHistorico" */'../pages/Reportes/GraficasHistorico'));
const GraficaChartsObras = lazy(() => import(/* webpackChunkName:"GraficaChartsObras" */'../pages/Reportes/GraficaChartsObras'));
const NewBimModel = lazy(() => import(/* webpackChunkName:"NewBimModel" */'../pages/BimModels/NewBimModel'));
const Roles = lazy(() => import(/* webChunkName:Roles */'../pages/Users/Roles'));
const Companies = lazy(() => import(/* webChunkName:Companies */'../pages/Companies/CompaniesMenu'));
const Projects = lazy(() => import(/* webChunkName:Projects */'../pages/Projects/Projects'));
const ClientsEra = lazy(() => import(/* webChunkName:ClientsEra */'../pages/ClientsEra/EraClients'));
const Providers = lazy(() => import(/* webChunkName:ClientsEra */'../pages/Providers/Providers'));
const Reports = lazy(() => import(/* webpackChunkName:"Reports" */'../pages/Reportes/Reportes'));
const GraficaPie = lazy(() => import(/* webpackChunkName:"GraficaPie" */'../pages/Reportes/GraficaPie'));
const MenuGantt = lazy(() => import(/* webpackChunkName:"GanttChart" */'../pages/GanttChart/Menu'));
const ManualGantt = lazy(() => import(/* webPackChunkName:"ManualGantt" */'../pages/GanttChart/Manual'));
const AutomaticGantt = lazy(() => import(/* webPackChunkName:"Automatic" */'../pages/GanttChart/Automatic'));
const GraficaProjects = lazy(() => import(/* webPackChunkName:"GraficaProjects" */'../pages/Reportes/GraficaProjects'));
const Users = lazy(() => import(/* webpackChunkName:"Users" */'../pages/Users/Users'));
const ShippmentChart = lazy(() => import(/* webPackChunkName:"ShippmentChart" */'../pages/Reportes/GraficaEmbarques'));
const GraficaPiesasPorDia = lazy(() => import(/*webPackChunkName:"GraficaPiesasPorDia"*/'../pages/Reportes/GraficaPiesasPorDia'));
const ProductionPanel = lazy(() => import(/* webpackChunkName:"ProductionPanel" */'../pages/ProductionPanel/ProductionPanel'));
const UnitPriceMatriz = lazy(() => import(/* webpackChunkName:"UnitPriceMatriz"*/ '../pages/Concepts/UnitPriceMatriz'));
const Consumables = lazy(() => import(/* webpackChunkName:"Consumables"*/ '../pages/Concepts/Consumables'));
const LinkConcept = lazy(() => import(/* webpackChunkName:"LinkConcepto"*/ '../pages/Concepts/LinkConcept'));
const ConceptKeys = lazy(() => import(/* webPackChunkName:"ConceptKeys"*/ '../pages/Concepts/ConceptKeys'));
const UnitPriceExcel = lazy(() => import(/* webpackChunkName:"UnitPriceExcel"*/ '../pages/Concepts/UniPriceExcel'));
const UploadUnitPriceExcel = lazy(() => import(/* webpackChunkName:"UploadUnitPriceExcel"*/ '../pages/Concepts/UploadCatalogByExcel'));
const AssemblyTypes = lazy(() => import(/* webpackChunkName:"AssemblyTypes"*/ '../pages/Concepts/AssemblyTypes'));
const AddAssemblyTypes = lazy(() => import(/* webpackChunkName:"AddAssemblyTypes"*/'../pages/Concepts/AddAssemblyType'));
const EditAssemblyTypes = lazy(() => import(/* webpackChunkName:"EditAssemblyTypes"*/'../pages/Concepts/EditAssemblyTypes'));
//ROUTE PATHS 
export const ROUTES_PATH = {
  UNITPRICE_EXCEL: '/concepts/unitPriceExcel',
  UNITPIRCE_UPLOAD_EXCEL: '/concepts/unitPriceExcel/upload',
  ASSEMBLY_TYPES: '/concepts/assemblyTypes',
  ADD_ASSEMBLY_TYPES: '/concepts/assemblyTypes/add',
  EDIT_ASSEMBLY_TYPES: '/concepts/assemblyTypes/:id/project/:projectId',
} as const;

export const CLIENTS_ROUTE: IRoute = {
  path: "/clients",
  to: "/clients",
  Component: Clientes,
  name: "Clients"
}
export const HOME_ROUTE: IRoute = {
  path: "/home",
  to: "/home",
  Component: Home,
  name: "Home"
}
//PROJECT ROUTES
export const routes: IRoute[] = [
  CLIENTS_ROUTE,
  HOME_ROUTE,
  {
    path: "/projects",
    to: "/projects",
    Component: Works
  },
  {
    path: "/management/stations",
    to: "/management/stations",
    Component: StationMenu,
    name: "Stations"
  },
  {
    path: "bim/models",
    to: "bim/models",
    Component: BimModelsMenu
  },
  {
    path: "bim/new",
    to: "bim/new",
    Component: NewBimModel,
    name: "New Bim"
  },
  {
    path: "bim/view",
    to: "bim/view",
    Component: CreateViewBim,
    name: "Create View"
  },
  {
    path: "/bim/editView",
    to: "/bim/editView",
    Component: EditViewBim,
    name: "Edit View"
  },
  {
    path: "processEditor",
    to: "processEditor",
    Component: ProcessEditor,
    name: "Paths"
  },
  {
    path: "processEditor/:id",
    to: "processEditor/:id",
    Component: PathEditor,
    name: "Path/Routes"
  },
  {
    path: "DrawingManagement/Index/:id",
    to: "DrawingManagement/Index/:id",
    Component: ControlPlanosIndex,
  },
  {
    path: "DrawingManagement",
    to: "DrawingManagement",
    Component: ControlPlanosMenu,
  },
  {
    path: "projectreports",
    to: "projectreports",
    Component: Reports
  },
  {
    path: "projectreports/pieces/project",
    to: "projectreports/pieces/project",
    Component: GraficaObra
  },
  {
    path: "projectreports/pieces/record",
    to: "projectreports/pieces/record",
    Component: GraficaHistorico
  },
  {
    path: "reports/pieces",
    to: "reports/pieces",
    Component: GraficaPiezas
  },
  {
    path: "reports/obras",
    to: "reports/obras",
    Component: GraficaChartsObras
  },
  {
    path: "reports/pie/chart",
    to: "reports/pie/chart",
    Component: GraficaPie
  },
  {
    path: "gantt",
    to: "gantt",
    Component: MenuGantt,
    name: "Gantt"
  },
  {
    path: "gantt/manual",
    to: "gantt/manual",
    Component: ManualGantt
  },
  {
    path: "gantt/automatic",
    to: "gantt/automatic",
    Component: AutomaticGantt
  },
  {
    path: "/roles",
    to: "/roles",
    Component: Roles
  },
  {
    path: "/companies/manage",
    to: "/companies/manage",
    Component: Companies
  },
  {
    path: "/projects/manage",
    to: "/projects/manage",
    Component: Projects
  },
  {
    path: "/clientsEra/manage",
    to: "/clientsEra/manage",
    Component: ClientsEra
  },
  {
    path: "/providers/manage",
    to: "/providers/manage",
    Component: Providers
  },
  {
    path: "reports/chart/projects",
    to: "reports/chart/projects",
    Component: GraficaProjects
  },
  {
    path: 'users',
    to: 'users',
    Component: Users
  },
  {
    path: "/reports/Shipments",
    to: "/reports/Shipments",
    Component: ShippmentChart
  },
  {
    path: "/reports/PiecesPerDay",
    to: "/reports/PiecesPerDay",
    Component: GraficaPiesasPorDia
  },
  {
    path: '/productionPanel',
    to: '/productionPanel',
    Component: ProductionPanel
  },
  {
    path: '/concepts/unitPrice',
    to: '/concepts/unitPrice',
    Component: UnitPriceMatriz
  },
  {
    path: '/concepts/unitPrice/:id/consumables',
    to: '/concepts/unitPrice/:id/consumables',
    Component: Consumables
  },
  {
    path: '/concepts/link',
    to: '/concepts/link',
    Component: LinkConcept
  },
  {
    path: '/concepts/keys',
    to: '/concepts/keys',
    Component: ConceptKeys
  },
  {
    path: ROUTES_PATH.UNITPRICE_EXCEL,
    to: ROUTES_PATH.UNITPRICE_EXCEL,
    Component: UnitPriceExcel,
    name: "Unit price by excel"
  },
  {
    path: ROUTES_PATH.UNITPIRCE_UPLOAD_EXCEL,
    to: ROUTES_PATH.UNITPIRCE_UPLOAD_EXCEL,
    Component: UploadUnitPriceExcel
  },
  {
    path: ROUTES_PATH.ASSEMBLY_TYPES,
    to: ROUTES_PATH.ASSEMBLY_TYPES,
    Component: AssemblyTypes,
    name: "Assembly Types"
  },
  {
    path: ROUTES_PATH.ADD_ASSEMBLY_TYPES,
    to: ROUTES_PATH.ADD_ASSEMBLY_TYPES,
    Component: AddAssemblyTypes
  },
  {
    path: ROUTES_PATH.EDIT_ASSEMBLY_TYPES,
    to: ROUTES_PATH.EDIT_ASSEMBLY_TYPES,
    Component: EditAssemblyTypes
  }
]

