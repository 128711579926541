import type { MenuProps } from 'antd';
import { IconBIM, IconManagement, IconHome, IconProductionPanel } from '../Icons';
import { BarChartOutlined, BookOutlined, ClusterOutlined, DiffOutlined, FileExcelOutlined, KeyOutlined, LinkOutlined, MoneyCollectOutlined, ScheduleOutlined } from '@ant-design/icons';
import { ISelectedWork, userType } from 'interfaces/IUsuario';
import { HOME_ROUTE } from 'routes/routes';

type MenuItem = Required<MenuProps>['items'];

const MenuItems = (user: userType | null, selectedProject?: ISelectedWork | null): MenuItem => {

  const permissions = new Set<string>();
  permissions.add(HOME_ROUTE.name ?? "");
  user?.role.permissions.forEach(permission => {
    permissions.add(permission.name);
  });

  /*ASIGNAMOS LOS ITEM DEL MENU */
  const items: MenuItem = [
    {
      label: "Home",
      key: selectedProject ? "/home" : "/clients",
      icon: <IconHome style={{ color: "var(--darkgray)" }} />
    },
    {
      label: 'Production Panel',
      key: '/productionPanel',
      icon: <IconProductionPanel style={{ color: "var(--darkgray)" }} />
    },
    {
      label: 'Concepts',
      key: '/concepts',
      icon: <BookOutlined style={{ color: "var(--darkgray)" }} />,
      children: [
        {
          title: "Unit Price",
          label: "Unit Price",
          key: "/concepts/unitPrice",
          icon: <MoneyCollectOutlined style={{ color: "var(--darkgray)" }} />
        },
        {
          title: "Unit Price By Excel",
          label: "Unit Price by Excel",
          key: '/concepts/unitPriceExcel',
          icon: <FileExcelOutlined style={{ color: "var(--darkgray)" }} />
        },
        {
          title: "Assembly Types",
          label: "Assembly Types",
          key: "/concepts/assemblyTypes",
          icon: <ScheduleOutlined style={{ color: "var(--darkgray)" }} />
        },
        {
          title: 'Link AssemblyType',
          label: 'Link AssemblyType',
          key: '/concepts/link',
          icon: <LinkOutlined style={{ color: "var(--darkgray)" }} />
        },
        {
          title: 'Concept Keys',
          label: 'Concept Keys',
          key: '/concepts/keys',
          icon: <KeyOutlined style={{ color: "var(--darkgray)" }} />
        }
      ]
    },
    {
      label: "Stations",
      key: "/management/stations",
      icon: <ClusterOutlined style={{ color: "var(--darkgray)" }} />
    },
    {
      label: "Reports",
      key: "/reports",
      icon: <BarChartOutlined style={{ color: "var(--darkgray)" }} />,
      children: [
        {
          title: "Pieces / Pounds per path and station",
          label: "Pieces / Pounds per path and station",
          key: "/reports/pieces"
        }, {
          title: "Projects information",
          label: "Projects information",
          key: "/reports/obras",
          disabled: true
        }
      ]
    },
    {
      label: "Catalogs",
      key: "/companies",
      icon: <DiffOutlined style={{ color: "var(--darkgray)" }} />,
      children: [
        {
          label: "Companies",
          key: "/companies/manage"
        }, {
          label: "Projects",
          key: "/projects/manage"
        },
        {
          label: "Era Clients",
          key: "/clientsEra/manage"
        },
        {
          label: "Providers",
          key: "/providers/manage"
        }
      ]
    },
    {
      label: "Management",
      key: "/management",
      icon: <IconManagement style={{ color: "var(--darkgray)" }} />,
      children: [
        {
          label: "Paths",
          key: "/processEditor"
        },
        {
          label: "User Manager",
          key: "/userManager",
          children: [
            {
              label: "Users",
              key: "/users"
            },
            {
              label: "Roles",
              key: "/roles"
            },
            {
              label: "Alerts",
              key: "/users/alerts"
            }
          ]
        },
      ]
    },
    {
      label: "BIM",
      key: "/bim",
      icon: <IconBIM style={{ color: "var(--darkgray)" }} />,
      children: [
        {
          label: "Upload",
          key: "/bim/new"
        }, {
          label: "Models",
          key: "/bim/models",

        }, {
          label: "Create View",
          key: "/bim/view"
        }, {
          label: "Edit View",
          key: "/bim/editView"
        },
      ]
    }
  ];

  if(user?.admin || user?.superAdmin) return items;
  
  disabledMenuItems(items, permissions);
  return items;
}

const disabledMenuItems = (items: any[], permissions: Set<string>): void => {
  items.forEach((item: any) => {
    item.disabled = !permissions.has(item.label);
    item?.children && disabledMenuItems(item.children, permissions);
  })
}

export default MenuItems;