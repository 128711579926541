import { asyncRequest, requestAsync } from "api/fetch";
import { toast } from "react-toastify";
import { useAuthUser } from "contexts/authContext";
import { useState } from "react";
import { saveAs } from "file-saver";
import JSZip from 'jszip';


interface IUseDownloadFiles {
    DownloadZip: (paths: any[]) => Promise<any>
    Getpaths: () => Promise<any>
    pathsFile: any[]
}

export const useDownloadFiles = (): IUseDownloadFiles => {
    const { user } = useAuthUser();
    const [pathsFile, setPathsFile] = useState<any[]>([]);

    const Getpaths = async () => {
        const response = await requestAsync(`/api/clientsEra/backUp/${user?.clienteEra}`, 'GET', null, false);
        if (!response.isOk) return toast.warning(response.error);
        const paths = response.result;
        setPathsFile(paths); 
        return paths;
    }

    const DownloadZip = async ( paths: any[]) => {
        toast.success('Loadin files, please wait a moment, this may take a few minutes');
        let getFiles: any[] = [];
        try {

            for (const path of paths) {
                const file = await asyncRequest({
                    url: `/api/clientsEra/download`,
                    method: 'POST',
                    withCredentials: true,
                    responseType: 'blob',
                    data: { path: path.path }
                });
                if (!file.isOk) return toast.warning('Corrupted File');
                let data = { File: file.result, name: path.path.split('/')[path.path.split('/').length - 1] }
                getFiles.push(data);
            }
            if (getFiles.length > 0) {
                generateZip(getFiles);
            };
        } catch (err) {
            toast.warning('Files not found: ' + err);
        }
    }

    const generateZip = async (files: any[]) => {
        if (files.length === 0) return toast.warning('No files to back up');
        toast.success('generating ZIP file');
        const zip = new JSZip();
        files.forEach((file) => {
            zip.file(file.name, file.File, { base64: true })
        });
        //get client era name
        const response = await requestAsync(`/api/clientsEra/${user?.clienteEra}`, 'GET', null, true);
        if (!response.isOk) return toast.warning('Client era information not found');

        const zipped = await zip.generateAsync(
            {
                type: 'blob',
                comment: `Backup ${response.result.razon_social}`,
            }
        );
        toast.success(`Backup ready to save`);
        saveAs(zipped, `${response.result.razon_social}`)
    }

    return {
        DownloadZip,
        Getpaths,
        pathsFile
    }
}