import {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
  // useMemo,
  useRef
} from "react";
import { TopBar } from "./layout/TopBar";
import { Layout, Image } from "antd";
import { PageLoader } from "./components/PageLoader";
import { ISelectedWork, IEmpresas, SelectOption, IMenusApp, MenuApp } from "interfaces/IUsuario";
import Header from "layout/BreadCrums";
import { Navigate, Outlet } from "react-router-dom";
import Menu from "layout/Menu";
import { ToastContainer } from 'react-toastify';
import { DEFAULT_MENU_STATES, KEY_STORAGE_CLIENT, KEY_STORAGE_WORK, UserStatus } from 'constants/IUsuario';
import { IconHome } from 'assets/index';
import { useAuthUser } from "contexts/authContext";
import "./App.less";
import 'react-toastify/dist/ReactToastify.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devexpress-gantt/dist/dx-gantt.css';
import { SWRConfig } from "swr";


interface IContextAuth {
  selectedWork?: ISelectedWork | null;
  selectedClient?: IEmpresas | null;
  onAssignOption: (select: ISelectedWork | IEmpresas, option: SelectOption) => void;
  handlerShowMenus: (menu: MenuApp, defaultValue?: boolean) => void;
  menusState: IMenusApp;
  clearData: () => void;
  modeMobile: boolean;
}

export const AuthContext = createContext({} as IContextAuth);
const { Provider } = AuthContext;

function App() {
  const { ready, user } = useAuthUser();
  const [selectedWork, setSelectedWork] = useState<ISelectedWork | null>();
  const [selectedClient, setSelectedClient] = useState<IEmpresas | null>();
  const [menusState, setMenuState] = useState<IMenusApp>(DEFAULT_MENU_STATES);
  const [modeMobile, setModeMobile] = useState<boolean>(false);
  const mounted = useRef<boolean>(false);

  const onAssignOption = useCallback((select: ISelectedWork | IEmpresas, option: SelectOption): void => {
    if (option === "Client") {
      localStorage.setItem(KEY_STORAGE_CLIENT, JSON.stringify(select));
      setSelectedClient(select as IEmpresas);
      return
    }
    localStorage.setItem(KEY_STORAGE_WORK, JSON.stringify(select));
    setSelectedWork(select as ISelectedWork);
  }, []);

  const handlerShowMenus = (menu: MenuApp, defaultValue?: boolean): void => {
    const showMenu: boolean = defaultValue ?? !menusState[menu];
    if (showMenu === menusState[menu]) return
    setMenuState({ ...menusState, [menu]: showMenu });
  }

  const assignMobileMenu = useCallback((): void => {
    const screenX: number = window.screen.width;
    setModeMobile(screenX < 500);
  }, []);

  const clearData = useCallback((): void => {
    setSelectedClient(null);
    setSelectedWork(null);
  }, []);

  /**GET STORAGED PROJECT AND CLIENT */
  useEffect(() => {
    const getStorageData = (): void => {
      if (localStorage.getItem(KEY_STORAGE_CLIENT)) {
        const storagedClient: IEmpresas = JSON.parse(localStorage.getItem(KEY_STORAGE_CLIENT)!);
        setSelectedClient(storagedClient);
      }
      if (localStorage.getItem(KEY_STORAGE_WORK)) {
        const storagedWork: ISelectedWork = JSON.parse(localStorage.getItem(KEY_STORAGE_WORK)!);
        setSelectedWork(storagedWork);
      }
    }
    getStorageData();
  }, []);

  /*DETECTE WINDOWS SIZE (MOBILE) */
  useEffect(() => {
    if (!mounted.current) {
      assignMobileMenu();
      mounted.current = true;
    }
    window.addEventListener("resize", assignMobileMenu);
    return () => {
      window.removeEventListener("resize", assignMobileMenu);
    }
  }, [assignMobileMenu]);

  /*DISABLED RIGHT CLICK */
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return
    window.addEventListener("contextmenu", (e) => e.preventDefault(), false);
    return () => {
      window.removeEventListener("contextmenu", (e) => e.preventDefault(), false);
    }
  });

  if (ready === UserStatus.GetSession) {
    return <PageLoader message="Getting session" showLogos={false} />;
  }

  if (!user) {
    return <Navigate to={"/login"} replace />
  }

  return (
    <Provider value={{ selectedWork, selectedClient, onAssignOption, menusState, handlerShowMenus, clearData, modeMobile }}>
      <SWRConfig >
        <Layout>
          {user && (
            <>
              <Menu />
              <Layout.Header
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "0 0 35px 20px",
                  height: '64px',
                  borderBottom: "1px solid #f0f0f0"
                }}
              >
                <div
                  style={{
                    float: "left",
                    height: "100%",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Image
                    height={30}
                    width={87}
                    src={"/ERAPBS.png"}
                    preview={false}
                  />
                </div>
                <TopBar />
              </Layout.Header>
            </>
          )}
          <Layout.Content
            style={{
              backgroundColor: "var(--gray5)",
              minWidth: user ? "calc(100% - 3rem)" : "100%",
              position: "absolute",
              top: user ? '64px' : 0,
              left: user ? '3.9rem' : 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              padding: "0 1.5rem 1rem 1.5rem",
              transition: "all .265s ease"
            }}
            onMouseOver={() => setMenuState(DEFAULT_MENU_STATES)}
          >
            <Header icon={IconHome} />
            <ToastContainer position="top-right" style={{ height: "50px" }} />
            <Outlet />
          </Layout.Content>
        </Layout>
      </SWRConfig>
    </Provider>
  );
}
export const useAuth = (): IContextAuth => {
  return useContext(AuthContext);
}

export default App;
