import axios, { AxiosRequestConfig, ResponseType } from "axios";

export type Method =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK';

export interface IResponse<T = any> {
    status: number;
    error?: any;
    isOk: boolean;
    result?: T;
}

export interface Headers {
    [key: string]: string;
}
export interface RequestsAsync {
    url: string;
    method: Method;
    data?: any;
    withCredentials?: boolean,
    headers?: Headers,
    responseType?: ResponseType,
    onDownloadProgress?: (progress: any) => void;
    onUploadProgress?: (progressEvent: any) => void;
}

export const enum HTTPResponse {
    SERVER = 500,
    NOT_FOUND = 404,
    BAD_REQUEST = 400,
    OK = 200,
    CREATED = 201,
    UNAUTHORIZED = 401,
    BAD_ENTITY = 422,
}
export const getAsyncData = async (url: string, withCredentials?: boolean): Promise<IResponse> => {
    return await axios.get(url, { withCredentials })
        .then((response) => {
            return {
                status: response.status,
                isOk: true,
                result: response.data
            }
        })
        .catch((err) => {
            return {
                isOk: false,
                status: err.request.status,
                error: err.request.response
            }
        });
}

export type httpClient = <T = any>({ url, method, data, headers, withCredentials, responseType, onDownloadProgress }: RequestsAsync) => Promise<IResponse<T>>;

export const asyncRequest: httpClient = async <T = any>({
    url,
    method,
    data,
    headers,
    withCredentials,
    responseType,
    onDownloadProgress,
    onUploadProgress
}: RequestsAsync): Promise<IResponse<T>> => {
    const options: AxiosRequestConfig = {
        data,
        method,
        url,
        withCredentials,
        headers,
        responseType,
        onDownloadProgress,
        onUploadProgress
    };
    return await axios(options)
        .then((response) => {
            return {
                status: response.status,
                isOk: true,
                result: response.data
            }
        })
        .catch((err) => {
            const message = err?.response?.data?.error ??
                err?.response?.data ??
                err.request?.response?.message ??
                err?.request?.response?.error;

            return {
                isOk: false,
                status: err.request.status,
                error: message
            }
        });
}

export const requestAsync = async (
    url: string,
    method: Method,
    data?: any,
    withCredentials?: boolean,
    headers?: Headers,
    responseType?: ResponseType,
    onDownloadProgress?: (progress: any) => void
): Promise<IResponse> => {
    const options: AxiosRequestConfig = {
        data,
        method,
        url,
        withCredentials,
        headers,
        responseType,
        onDownloadProgress
    };
    return await axios(options)
        .then((response) => {
            return {
                status: response.status,
                isOk: true,
                result: response.data
            }
        })
        .catch((err) => {
            return {
                isOk: false,
                status: err.request.status,
                error: err.request.response
            }
        });
}
