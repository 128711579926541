import { useEffect, useState } from "react";
import { asyncRequest } from "api/fetch";
import { IDownloadFiles } from "interfaces/IDownloadFiles";
import { toast } from "react-toastify";
import { truncateNumber } from 'utils/Format';
import { Button, LoadPanel, Popup, ScrollView } from 'devextreme-react';
import '../styles/dowloadTable.css'

export const DownloadFiles = ({ pathsFile, setBackUpModal, openModal }: IDownloadFiles): JSX.Element => {
    const [loading, setLoading] = useState<boolean>();

    // get name file
    const getName = () => {
        let array: any[] = [];
        pathsFile.map((name) => {
            let aux = name.path.split('/');
            array.push({ name: aux[aux.length - 1], size: name.size })
        });
        return array
    }

    const downloadFile = async (path: string) => {
        if (!path) return toast.warning('File name not found');
        setLoading(true)
        let pathTodownload = "";
        pathsFile.find((currentPath) => {
            let find = currentPath.path.split("/");
            if (find[find.length - 1] === path) {
                pathTodownload = currentPath.path;
            }
        })
        if (pathTodownload === "") return toast.warning('File not found');
        // get file from space S3
        try {
            const response = await asyncRequest({
                url: `/api/clientsEra/download`,
                method: 'POST',
                withCredentials: true,
                responseType: 'blob',
                data: { path: pathTodownload }
            });
            if (!response.isOk) return toast.warning(response.error);
            const file = response.result;
            if (file.size === 0) return toast.warning('Error with file');
            setLoading(false);
            saveAs(path, file);
            return
        } catch (err) {
            console.log(err);
            toast.error('Error with file');
            setLoading(false)
        }
    }

    const saveAs = async (name: string, file: Blob) => {
        if (name === "") return toast.warning('name not found');
        const element = document.createElement("a");
        if (!file) return toast.warning('file not found');
        const urlToDownload = URL.createObjectURL(file);
        element.href = urlToDownload;
        element.download = name;
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(urlToDownload);
    }

    useEffect(() => {
        getName();
    }, [getName, pathsFile]);

    return (
        <Popup
            height={"auto"}
            width={'auto'}
            maxHeight={"80%"}
            maxWidth={"60%"}
            visible={openModal}
            showCloseButton={true}
            onHidden={() => setBackUpModal(false)}
            title={"Download Files"}
            dragEnabled={false}

        >
            {
                loading && <LoadPanel message={'Loading'} visible={loading} />
            }
            <ScrollView>
                <div className="content-table">
                    <table className="styled-table">
                        <thead className="table-head">
                            <tr>
                                <th className="t-name">File name</th>
                                <th className="t-size">Size</th>
                                <th className="t-button">Download</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                pathsFile.length && getName().length
                                    ? (
                                        getName().map((path, key) => (
                                            <tr key={key} className="active-row">
                                                <td className="table-td">
                                                    {path.name}
                                                </td>
                                                <td className="table-td">
                                                    {truncateNumber((path.size / 1048576), 2) < 1 ? truncateNumber((path.size / 1048576), 2) + ' KB' : truncateNumber((path.size / 1048576), 2) + ' MB'}
                                                </td>
                                                <td className="table-td">
                                                    <Button
                                                        icon={"download"}
                                                        type='success'
                                                        stylingMode="outlined"
                                                        width={60}
                                                        height={40}
                                                        onClick={() => downloadFile(path.name)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                        )
                                    ) : (<></>)
                            }
                        </tbody>
                    </table>
                </div>
            </ScrollView>
        </Popup>


    );
}