export interface IPaths {
  name: string;
  redirect?: string;
  children?: IPaths[];
}
export const MENU_PATH_NAME: string = "Menu";
export const DRAWING_MANAGEMENT: string = "/DrawingManagement/Index";

export const ThreePaths: IPaths[] = [
  {
    name: "Home",
    redirect: "/clients",
    children: [
      {
        name: "Projects",
        redirect: "/projects",
        children: [
          {
            name: MENU_PATH_NAME,
            redirect: "/home",
            children: [
              {
                name: "View BIM Models",
              },
              {
                name: "Production Orders",
              },
              {
                name: "Project Management",
                redirect: "/DrawingManagement",
                children: [
                  {
                    name: "Quality Control",
                    redirect: `${DRAWING_MANAGEMENT}/QualityControl`
                  },
                  {
                    name: "Documentation",
                    redirect: `${DRAWING_MANAGEMENT}/Documentation`
                  },
                  {
                    name: "Engineering Drawings",
                    redirect: `${DRAWING_MANAGEMENT}/EngineeringDrawings`
                  },
                  {
                    name: "Meeting Minutes",
                    redirect: `${DRAWING_MANAGEMENT}/MeetingMinutes`
                  },
                  {
                    name: "Shop Drawings",
                    redirect: `${DRAWING_MANAGEMENT}/ShopDrawings`
                  },
                  {
                    name: "Budget",
                    redirect: `${DRAWING_MANAGEMENT}/Budget`
                  },
                  {
                    name: "Construction Schedules",
                    redirect: `${DRAWING_MANAGEMENT}/ConstructionSchedules`
                  },
                  {
                    name: "Reports",
                    redirect: `${DRAWING_MANAGEMENT}/Reports`
                  }
                ]
              },
              {
                name: "Reports",
                redirect: "/projectreports",
                children: [
                  {
                    name: "Pieces per station",
                    redirect: "/projectreports/pieces/project"
                  },
                  {
                    name: "Pieces per route (record)",
                    redirect: "/projectreports/pieces/record"
                  },
                  {
                    name: "Breakdown Cost",
                    redirect: "/reports/pie/chart"
                  },
                  {
                    name: "Projects Report",
                    redirect: "/reports/chart/projects"
                  },
                  {
                    name: "Shipments Report",
                    redirect: "/reports/Shipments"
                  },
                  {
                    name: "Pieces per day report",
                    redirect: "/reports/PiecesPerDay"
                  }
                ]
              }, {
                name: "Gantt",
                redirect: "/gantt",
                children: [
                  {
                    name: "Manual",
                    redirect: "/gantt/manual"
                  },
                  {
                    name: "Automatic",
                    redirect: "/gantt/automatic"
                  }
                ]
              }
            ]
          }

        ]
      },
    ]
  },
  {
    name: 'Production Panel',
    redirect: '/productionPanel'
  },
  {
    name: 'Concepts',
    children: [
      {
        name: 'Unit Price',
        redirect: '/concepts/unitPrice',
        children: [
          {
            name: 'Consumables By Unit Price',
            redirect: '/concepts/unitPrice/*/consumables'
          }
        ]
      },
      {
        name: 'Link',
        redirect: '/concepts/link'
      },
      {
        name: 'Keys',
        redirect: '/concepts/keys'
      },
      {
        name: 'Unit Price By Excel',
        redirect: '/concepts/unitPriceExcel',
        children: [
          {
            name: 'Upload Unit Prices',
            redirect: '/concepts/unitPriceExcel/upload'
          }
        ]
      },
      {
        name: "Assembly Types",
        redirect: '/concepts/assemblyTypes',
        children: [
          {
            name: "Add",
            redirect: '/concepts/assemblyTypes/add'
          },
          {
            name: "Edit",
            redirect: '/concepts/assemblyTypes/*/project/*'
          }
        ]
      }
    ]
  },
  {
    name: "Stations",
    redirect: "/management/stations"
  },
  {
    name: "Management",
    children: [
      {
        name: "Clients",
      },
      {
        name: "Projects",
      },
      {
        name: "Users Manager",
        children: [
          {
            name: 'Users',
            redirect: '/users'
          },
          {
            name: "Roles",
            redirect: "/roles"
          }
        ]
      },
      {
        name: "Paths",
        redirect: "/processEditor",
        children: [
          {
            name: "Stations per path",
            redirect: "/processEditor/*"
          }
        ]
      },
    ]
  },
  {
    name: 'Stations',
    redirect: "/management/stations"
  },
  {
    name: "Reports",
    children: [
      {
        name: "PIECES / KILOGRAMS PER ROUTE AND STATIONS",
        redirect: "/reports/pieces"
      },
      {
        name: "Projects information",
        redirect: "/reports/obras"
      }
    ]
  },
  {
    name: "Projects",
    children: [
      {
        name: "Manage",
        redirect: "/projects/manage"
      },
      {
        name: "Manage",
        redirect: "/companies/manage"
      },
      {
        name: "Manage",
        redirect: "/clientsEra/manage"
      },
      {
        name: "Manage",
        redirect: "/providers/manage"
      }
    ]
  },
  {
    name: "Operator Profile",
    children: [
      {
        name: "Assigned Profiles",
      }
    ]
  },
  {
    name: "BIM",
    children: [
      {
        name: "Upload BIM Model",
        redirect: "/bim/new"
      },
      {
        name: "View BIM Models",
        redirect: "/bim/models"
      },
      {
        name: "Create View BIM",
        redirect: "/bim/view"
      },
      {
        name: "Edit View BIM",
        redirect: "/bim/editView"
      }
    ]
  }
];


export const getPaths = (
  arrayPaths: IPaths[],
  searchPath: string,
  roadPaths: IPaths[],
  getPathFound: (path: IPaths[]) => void
): void => {
  arrayPaths.forEach((path) => {
    const pathRedirect = path.redirect;
    
    if (pathRedirect && pathRedirect.toLocaleLowerCase() === searchPath.toLocaleLowerCase()) {
      roadPaths.push({ name: path.name, redirect: pathRedirect });
      getPathFound(roadPaths);
      return;
    }
    const paths = [...roadPaths, { name: path.name, redirect: pathRedirect }];
    getPaths(path.children || [], searchPath, paths, getPathFound);
  });
};
