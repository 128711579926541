import { HTTPResponse } from "api/fetch";
import { toast } from "react-toastify";

interface IHttpMessage {
  [key: number]: string;
}
export const HTTP_MESSAGES: IHttpMessage = {
  404: "La peticion al servidor es incorrecta",
}

export const showErrorsOnEntities = (error: any, status: number): void => {
  if (status === HTTPResponse.BAD_ENTITY) {
    for (const entity in error) {
      toast.error(`${error[entity].msg} : ${error[entity].path}`, { autoClose: false, closeOnClick: true })
    }
    return
  }
  toast.error(error, { autoClose: false })
}
