import { useNavigate } from 'react-router-dom';
import { useClientes } from './hooks/useClientes';
import { LoadPanel } from 'devextreme-react';
import './styles/styles.less';
const URL_IMAGE = 'https://era-storage.sfo3.digitaloceanspaces.com/';

const Clientes = () => {

  const navigate = useNavigate();
  const {
    companys = [],
    error,
    loading,
    onHandlerCompany
  } = useClientes();

  return (
    <>
      <LoadPanel visible={loading} message="Loading clients..." />
      <div className='container-options-clients' >
        {
          !error && companys?.map((company) => (
            <div
            className='item'
            onClick={() => onHandlerCompany(company, () => navigate(`/projects`, { state: "clients" }))}
            key={company.idempresa}
            >
              <img className='img-content' alt='Client' src={URL_IMAGE + company.pathImage} />
              <h3>{company.nombre.toUpperCase()}</h3>
              <p className='obras'>Active works: {company.obras}</p>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default Clientes