import { memo, useMemo, useCallback } from 'react';
import { Menu as MenuComponent } from 'antd';
import { useAuth } from 'App';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useAuthUser } from 'contexts/authContext';
import MenuItems from './config/config';

const Menu = () => {
  const { selectedWork, menusState, handlerShowMenus } = useAuth();
  const { user } = useAuthUser();
  
  const navigate = useNavigate();

  const onSelectOption = useCallback((option: any, redirectApp: boolean = false): void => {
    if (redirectApp) {
      window.location.pathname = option.key;
      return
    }
    navigate(option.key);
  }, [navigate]);

  const menuItems: MenuProps["items"] = useMemo(() => MenuItems(user, selectedWork), [selectedWork, user]);

  return (
    <MenuComponent
      items={menuItems}
      style={{
        position: "absolute",
        left: 0,
        top: "64px",
        bottom: 0,
        height: "calc(100vh - 64px)",
        width: !menusState.options ? "4rem" : "15rem",
        zIndex: 11,
        overflowX: "hidden",
        overflowY: "visible",
        wordWrap: "break-word",
        userSelect: "none"
      }}
      inlineCollapsed={!menusState.options}
      inlineIndent={25}
      theme="light"
      mode='inline'
      lang='en'
      onClick={onSelectOption}
      onMouseOver={() => handlerShowMenus("options", true)}
    />
  )
}

export default memo(Menu)