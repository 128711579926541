import { useAuth } from 'App';
import { memo, ReactElement, CSSProperties, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPaths, ThreePaths, getPaths, MENU_PATH_NAME } from 'routes/threePaths';
import { FormatFistUpperCase } from 'utils/Format';
import './styles.less';

interface IProps {
  icon?: string;
  children?: ReactElement | ReactElement[];
  style?: CSSProperties;
  fontSize?: string | number;
  className?: string;
}

const Header = ({ icon, className, fontSize, style, children }: IProps) => {
  
  const { selectedWork } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath: string = useMemo(() => {
    const state: any = location.state
      ? typeof location.state === 'number' || typeof location.state === 'object'
        ? '*'
        : location.state
      : '*';
    return location.pathname.replace(/([0-9]+)/g, state)
  }, [location]);

  const breadCrums: IPaths[] = useMemo(() => {
    let roadPaths: IPaths[] = [];
    getPaths(ThreePaths, currentPath, [], (paths) => {
      roadPaths = paths.map((path) =>
        path.name === MENU_PATH_NAME ?
          { ...path, name: selectedWork?.work ?? MENU_PATH_NAME }
          : path
      );
    });
    return roadPaths;
  }, [currentPath, selectedWork]);

  return (
    <nav style={style} className={`header ${className}`} >
      <div className='content-title' style={{ fontSize }}>
        <h2 className='title'>
          {
            breadCrums.map((path, index) => (
              <div key={index}
                style={{ color: (currentPath === path.redirect || !path.redirect) ? "var(--darkgray)" : "var(--teal)" }}
                className="container-path">
                {index === 0 && (<img src={icon} alt="Home" className='icon' />)}
                <span
                  className={(path.redirect && currentPath !== path.redirect) ? "path" : "no-path"}
                  onClick={() => path.redirect && navigate(path.redirect, { state: location.state })}
                >
                  {FormatFistUpperCase(path.name)}
                </span>
                <span
                  style={{ margin: "0 .25rem" }}
                >
                  {index !== (breadCrums.length - 1) && ">"}
                </span>
              </div>
            ))
          }
        </h2>
      </div>
      {children}
    </nav>
  )
}

export default memo(Header)