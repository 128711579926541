import LogIn from 'pages/Login/LogIn'
import { Route, Routes, Navigate } from 'react-router-dom'
import { CLIENTS_ROUTE, IRoute, routes } from './routes';
import { memo, useMemo } from 'react';
import { PrivateRoute, DefaultNavigate } from './PrivateRoute';
import App from 'App';
import { useAuthUser } from 'contexts/authContext';

const Navigation = () => {

  const { user } = useAuthUser();

  const accessRoutes = useMemo(() => {
    if (!user?.role.permissions) return [];

    if (user.admin || user.superAdmin) return routes;
    const permissions = new Set<IRoute>();
    permissions.add(CLIENTS_ROUTE);

    user.role.permissions.forEach(permission => {
      const route = routes.find(route => route?.name
        ? route.name.toLocaleLowerCase() === permission.name.toLocaleLowerCase()
        : route.path.toLocaleLowerCase() === permission.pathName?.toLocaleLowerCase());
      if (route) permissions.add(route);
    })
    return Array.from(permissions.values());
  }, [user]);

  return (
    <Routes>
      <Route path='login' element={<LogIn />} />
      <Route index={false} element={<App />}>
        {
          accessRoutes.map(({ Component, path, items = [] }) =>
          (
            items.length > 0 ? (
              <Route
                path={path}
                caseSensitive={false}
                key={path}
                element={<PrivateRoute Component={Component} />}>
                {
                  items.map((subRoute) => (
                    <Route
                      path={subRoute.path}
                      key={subRoute.path}
                      caseSensitive={false}
                      element={<PrivateRoute Component={subRoute.Component} />} />
                  ))
                }
              </Route>
            ) : (
              < Route
                key={path}
                path={path}
                caseSensitive={false}
                element={<PrivateRoute Component={Component} />} />
            )
          ))
        }
        <Route
          path='*'
          element={
            <PrivateRoute
              Component={DefaultNavigate({ Element: <Navigate to="/clients" replace /> })}
            />}
        />
      </Route >
    </Routes>
  )
}

export default memo(Navigation)