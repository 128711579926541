import { useCallback, useEffect, useState, createContext, ReactElement, useContext, useMemo } from 'react';
import { HTTPResponse, asyncRequest, requestAsync } from 'api/fetch';
import { IAction, userType } from 'interfaces/IUsuario';
import { message } from 'antd';
import { UserStatus } from 'constants/IUsuario';
import { useLocation } from 'react-router-dom';

interface IContextAuth {
  user: userType | null;
  wasLoggedOut: boolean;
  currentPagePermissions?: IAction;
  ready: UserStatus;
  signin: (username: string, password: string) => Promise<boolean>;
  signout: () => Promise<void>;
  verifyRedirect: () => void;
}
const AuthContext = createContext({} as IContextAuth);
const { Provider } = AuthContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

const REDIRECT: string = "?from=";

const AUTH_API = {
  logIn: '/api/login',
  logOut: '/api/logout',
  getActiveUser: '/api/userData',
  getPermissions: '/api/users/roles/permissions'
} as const;

export const AuthProvider = ({ children }: IProps) => {
  const [user, setUser] = useState<userType | null>(null);
  const [wasLoggedOut, setWasLoggedOut] = useState<boolean>(false);
  const [ready, setReady] = useState<UserStatus>(UserStatus.GetSession);

  const location = useLocation();

  const signin = useCallback(
    async (username: string, password: string): Promise<boolean> => {
      setWasLoggedOut(false);
      const userInformation = await asyncRequest({
        url: AUTH_API.logIn,
        method: 'POST',
        data: { username, password },
        withCredentials: false
      });
      if (userInformation.result) {
        const permissions = await asyncRequest({ url: AUTH_API.getPermissions, method: "GET", withCredentials: true });
        if (permissions.result) {
          setUser({ ...userInformation.result, role: permissions.result });
        }
      }
      return !!userInformation.result;
    }, []);

  const signout = useCallback(async (): Promise<void> => {
    setReady(UserStatus.SignOut);
    const response = await requestAsync(AUTH_API.logOut, "GET", null, true);
    if (!response.isOk) {
      response.status !== HTTPResponse.UNAUTHORIZED && message.error("An error ocurred while trying to log out");
      window.location.reload();
    }
    response.isOk && message.success("The session was closed!");
    // CLEAR DATA
    setWasLoggedOut(true);
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    setReady(UserStatus.Stop);
  }, []);

  const verifyRedirect = (): void => {
    const existsRedirectURL: boolean = location.search.startsWith(REDIRECT);
    if (!existsRedirectURL) return;
    const urlRedirect = location.search.split(REDIRECT)[1];
    window.location.href = urlRedirect;
  }

  const currentPagePermissions = useMemo(() => {
    if (!user) return;
    const currentPath = location.pathname.toLocaleLowerCase();
    return user.role?.actions.find(item => item.pathName?.toLocaleLowerCase() === currentPath);
  }, [user, location.pathname]);

  /*GET DATA FROM ACTIVE USER */
  useEffect(() => {
    if (wasLoggedOut) return;

    const getUserData = async (): Promise<void> => {
      setReady(UserStatus.GetSession);
      const [sessionUser, permissions] = await Promise.all(
        [
          asyncRequest({ url: AUTH_API.getActiveUser, method: "GET", withCredentials: true }),
          asyncRequest({ url: AUTH_API.getPermissions, method: "GET", withCredentials: true })
        ]
      )
      if (!sessionUser.isOk || !permissions.isOk) {
        sessionUser.status !== HTTPResponse.UNAUTHORIZED && message.error("Error getting session");
        setUser(null);
      }
      if (sessionUser.isOk && permissions.isOk) {
        const userData: userType = { ...sessionUser.result, role: permissions.result };
        setUser(userData);
      }
      setReady(UserStatus.Stop);
    }
    getUserData();
  }, [wasLoggedOut]);

  return (
    <Provider value={{ signin, signout, user, wasLoggedOut, ready, verifyRedirect, currentPagePermissions }}>
      {children}
    </Provider>
  )
}

export const useAuthUser = (): IContextAuth => {
  return useContext(AuthContext);
}