import './styles.less';
import { FormatFistUpperCase } from '../utils/Format';
import { ReactElement } from 'react';

interface IProps {
  title?: string;
  subTitle?: string;
  children?: ReactElement | ReactElement[];
}
const SubHeader = ({ subTitle, title, children }: IProps) => {
  return (
    <nav className='sub-header'>
      <div>
        <p className='sub-title'>{FormatFistUpperCase(subTitle ?? "")}</p>
        <p className='title'>{FormatFistUpperCase(title ?? "")}</p>
      </div>
      {children}
    </nav>
  )
}

export default SubHeader